import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Improving end distrusts instantly',
    text: '“In all cases, there is a human in the loop,” Lamanna said. “This isn’t at all about replacing developers, it’s about finding the next 100 million developers in the world.',
  },
  {
    title: 'Become the tended active',
    text: 'And in the same way that you type a question into a search engine and then get to decide which result to click on, GPT-3 returns multiple suggestions for Power Fx formulas.',
  },
  {
    title: 'Message or am nothing',
    text: 'The Power Platform team used Azure Machine Learning managed endpoints, a new capability announced in preview at Build that helps people deploy models of all sizes in Azure',
  },
  {
    title: 'Really boy law county',
    text: 'The Power Platform team worked closely with the Azure AI team to fine tune a GPT-3 model using Azure Machine Learning that could translate between natural language and Power Fx expressions.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen.</h1>
      <p>Request Early Access to Get Started</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
