import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is GPT-3" text="Generative Pre-trained Transformer 3 (GPT-3) is an autoregressive language model that uses deep learning to produce human-like text. Using an advanced AI model like this can help our low-code tools become even more widely available to an even bigger audience by truly becoming what we call no code." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Chatbots" text="GPT-3 is the most powerful natural language processing model that we have in the market, so for us to be able to use it to help our customers is tremendous,” said Bryony Wolf, Power Apps product marketing manager." />
      <Feature title="Knowledgebase" text="We think there are a whole bunch more things that GPT-3 is capable of doing. It’s a foundational new technology that lights up a ton of new possibilities, and this is sort of that first light coming into production." />
      <Feature title="Education" text="This is really the first time you’re seeing in a mainstream consumer product the ability for customers to have their natural language transformed into code." />
    </div>
  </div>
);

export default WhatGPT3;
